import { Link } from "remix";
import { FaSearch } from "react-icons/fa";

import styles from "~/styles/components/interactive/button/dummy-search-link.css";

DummySearchLink.styles = styles;

export function DummySearchLink({
  placeholderText,
  href,
}: {
  placeholderText: string;
  href: string;
}) {
  return (
    <Link
      prefetch="intent"
      to={href}
      className="search-link-wrapper elevation-md"
    >
      <div className="search-link-icon-wrapper">
        <FaSearch aria-hidden="true" />
      </div>
      <div className="search-link-text">{placeholderText}</div>
    </Link>
  );
}
