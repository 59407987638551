import { ReactNode } from "react";

import { Card, SectionWrapper } from "~/src/components/display";

import styles from "~/styles/components/interactive/modal/modal-card.css";

ReachModalCard.links = [
  {
    rel: "stylesheet",
    href: styles,
  },
  { rel: "stylesheet", href: Card.styles },
];

type ModalCardProps = {
  children: ReactNode;
};

export function ReachModalCard({ children }: ModalCardProps) {
  return (
    <SectionWrapper className="modal-content-wrapper">
      <Card className="modal-card" addTopPadding={false} addTopAccent={false}>
        {children}
      </Card>
    </SectionWrapper>
  );
}
