import { ReactNode, useState, useEffect, Children } from "react";
import { FaChevronDown } from "react-icons/fa";

import styles from "~/styles/components/interactive/select.css";

Select.styles = styles;

/**
 * Render the option tags as children.
 */
export function Select({
  label,
  value,
  children,
  onChange,
  className,
  name,
  id,
  noJsSubmitButtonText,
  showNoJsSubmitButton,
}: SelectProps) {
  let [jsEnabled, setJsEnabled] = useState(false);
  const displayedValue = getDisplayedValue(value, children);

  useEffect(() => {
    setJsEnabled(true);
  }, []);

  return (
    <div className="select-wrapper">
      <div className="select-js-enabled-wrapper" data-js-enabled={jsEnabled}>
        <label className="visually-hidden" htmlFor={id}>
          {label}
        </label>
        <select
          data-selected-option={value}
          className="native-select"
          onChange={onChange}
          name={name}
          value={value}
          id={id}
        >
          {children}
        </select>
        <div
          className={`select-presentational elevation-sm${
            className ? " " + className : ""
          }`}
        >
          {displayedValue}
          <div className="select-down-icon-wrapper">
            <FaChevronDown />
          </div>
        </div>
      </div>
      <noscript className="select-noscript-wrapper">
        <div className="select-js-disabled-wrapper">
          <select
            className="no-js-select elevation-sm"
            name={name}
            onChange={() => {}}
            value={value}
            id={id}
          >
            {children}
          </select>
          {showNoJsSubmitButton && (
            <button className="no-js-submit-button elevation-sm" type="submit">
              {noJsSubmitButtonText}
            </button>
          )}
        </div>
      </noscript>
    </div>
  );
}

// type SelectProps = {
//   label: string;
//   value: string;
//   children: ReactNode;
//   onChange: any;
//   name: string;
//   id: string;
//   selected: string;
//   className?: string;
// };

type SelectProps = {
  label: string;
  value: string;
  children: ReactNode;
  onChange: any;
  name?: string;
  id: string;
  className?: string;
  noJsSubmitButtonText?: string;
  showNoJsSubmitButton?: boolean;
};

function getDisplayedValue(value: string, children: ReactNode) {
  const childArray = Children.toArray(children);
  const selectedChild = childArray.find((child) => {
    // @ts-ignore
    return child.props.value === value.trim().toLowerCase();
  });

  // @ts-ignore
  return selectedChild.props.children;
}

// USAGE

// function CategorySelectLetterControl({
//   label,
//   description,
// }: {
//   label: string;
//   description: string;
// }) {
//   const [value, SetValue] = useState("a");
//   return (
//     <div className="businesses-category-select-control-wrapper">
//       <div className="businesseses-label-description">{description}</div>
//       <Select
//         value={value}
//         noJsSubmitButtonText="GO"
//         onChange={
//           // @ts-ignore
//           (e) => SetValue(e.target.value)
//         }
//       >
//         {alphabet.map((letter) => {
//           return (
//             <option
//               key={letter}
//               value={letter}
//             >{`${letter.toUpperCase()}`}</option>
//           );
//         })}
//       </Select>
//     </div>
//   );
// }

// function CategorySelectCategoryControl({
//   label,
//   description,
// }: {
//   label: string;
//   description: string;
// }) {
//   const [value, SetValue] = useState("a");
//   return (
//     <div className="businesses-category-select-control-wrapper">
//       <div className="businesseses-label-description">{description}</div>
//       <Select
//         value={value}
//         noJsSubmitButtonText="GO"
//         onChange={
//           // @ts-ignore
//           (e) => SetValue(e.target.value)
//         }
//       >
//         {alphabet.map((letter) => {
//           return (
//             <option
//               key={letter}
//               value={letter}
//             >{`${letter.toUpperCase()}`}</option>
//           );
//         })}
//       </Select>
//     </div>
//   );
// }

// const alphabet = [
//   "a",
//   "b",
//   "c",
//   "d",
//   "e",
//   "f",
//   "g",
//   "h",
//   "i",
//   "j",
//   "k",
//   "l",
//   "m",
//   "n",
//   "o",
//   "p",
//   "q",
//   "r",
//   "s",
//   "t",
//   "u",
//   "v",
//   "w",
//   "x",
//   "y",
//   "z",
// ];
