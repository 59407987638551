export function validateTextarea(
  text: string,
  minChars: number = 2,
  maxChars: number = 60
) {
  let validationResponse = {
    valid: false,
    message: "Message not valid",
  };
  if (text.length < minChars) {
    return {
      ...validationResponse,
      message: `Please enter at least ${minChars} characters.`,
    };
  }
  if (text.length > maxChars) {
    return {
      ...validationResponse,
      message: `Please enter less than ${maxChars} characters.`,
    };
  }
  let regexNameTestValid = /^[^±@£%^§¡€#¢§¶•ªº«\\/<>;|=]{1,500}$/.test(text);
  if (!regexNameTestValid) {
    return {
      ...validationResponse,
      message: "Use letters, numbers and basic punctuation only.",
    };
  }

  return { valid: true, message: "" };
}
