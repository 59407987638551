import { ReactNode } from "react";
import { Button } from "./button";

import styles from "../../../../styles/components/interactive/button/button-with-icon.css";
import { RemixImage } from "../../display";

type ButtonWithIconsProps = {
  children: ReactNode;
  className?: string;
  type?: "link" | "submit" | "button";
  icon?: ReactNode;
  iconUrl?: string;
  iconAltText?: string;
  buttonPosition?: "left" | "right";
  size?: "small" | "medium" | "large";
  variant?: "fill" | "outline" | "ghost";
  onClick?: any;
  href?: string;
};

ButtonWithIcon.links = [
  { rel: "stylesheet", href: Button.styles },
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: RemixImage.styles },
];

export function ButtonWithIcon({
  children,
  className,
  icon,
  iconUrl,
  iconAltText,
  type = "link",
  buttonPosition = "right",
  size = "medium",
  variant = "fill",
  href,
  onClick,
}: ButtonWithIconsProps) {
  return (
    <Button
      className={`button-with-icon-button-wrapper${
        className ? " " + className : ""
      }`}
      size={size}
      variant={variant}
      onClick={onClick}
      href={href}
      type={type}
    >
      <div
        data-button-position={buttonPosition}
        data-icon-is-external={iconUrl ? true : false}
        data-size={size}
        className="button-with-icon-content-wrapper"
      >
        <div
          className="button-with-icon-icon-wrapper"
          data-icon-is-external={iconUrl ? true : false}
          data-size={size}
          data-button-position={buttonPosition}
        >
          {" "}
          {iconUrl ? (
            <RemixImage
              objectFit="contain"
              sanityUrl={iconUrl}
              alt={iconAltText || `${children} link icon`}
            />
          ) : (
            icon
          )}
        </div>
        <div className="button-with-icon-text-wrapper">{children}</div>
      </div>
    </Button>
  );
}
