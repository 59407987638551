import { ReactNode } from "react";
import { IoClose } from "react-icons/io5";

import styles from "~/styles/components/interactive/modal/reach-modal.css";

ReachModal.links = [
  {
    rel: "stylesheet",
    href: styles,
  },
];

export function ReachModal({
  titlebarName,
  titlebarColor,
  closeModal,
  children,
}: {
  titlebarName: string;
  titlebarColor: "indigo" | "cool-grey" | "orange" | "red" | "green" | "indigo";
  closeModal: any;
  children: ReactNode;
}) {
  return (
    <div className="reach-modal-card-wrapper">
      <ReachModalCardContentWrapper>
        <ReachModalTitleBarWrapper
          titlebarColor={titlebarColor}
          titlebarName={titlebarName}
          closePageModal={closeModal}
        />

        <ReachModalCardScrollableContentWrapper>
          {children}
        </ReachModalCardScrollableContentWrapper>
      </ReachModalCardContentWrapper>
    </div>
  );
}

function ReachModalCardContentWrapper({
  children,
  allowScrolling = false,
  className,
}: {
  children: ReactNode;
  allowScrolling?: boolean;
  className?: string;
}) {
  return (
    <div
      data-allow-scrolling={allowScrolling}
      className={`reach-modal-card-content-wrapper${
        className ? " " + className : ""
      }`}
    >
      {children}
    </div>
  );
}

function ReachModalTitleBarWrapper({
  closePageModal,
  titlebarColor,
  titlebarName,
}: {
  titlebarName: string;
  titlebarColor: "indigo" | "cool-grey" | "orange" | "red" | "green" | "indigo";

  closePageModal: any;
}) {
  return (
    <div
      className="reach-modal-title-bar-wrapper"
      data-title-bar-color={titlebarColor}
    >
      <ModalContentContainer className="h-full w-full">
        <div className="title-bar-wrapper">
          <h5 className="title-bar-text">{titlebarName}</h5>
          <button onClick={closePageModal} className="close-modal-button">
            <IoClose />
            <span className="visually-hidden">Close Modal</span>
          </button>
        </div>
      </ModalContentContainer>
    </div>
  );
}

function ReachModalCardScrollableContentWrapper({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`reach-modal-card-scrollable-content-wrapper${
        className ? " " + className : ""
      }`}
    >
      {children}
    </div>
  );
}

export function ModalContentContainer({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`modal-content-container${className ? " " + className : ""}`}
    >
      {children}
    </div>
  );
}
