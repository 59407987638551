import { DialogContent, DialogOverlay } from "@reach/dialog";
import { ReactNode, useState } from "react";

import styles from "~/styles/components/interactive/modal/reach-dialog.css";

import { ReachModalCard } from "./reach-modal-card";

useReachPageModal.links = [
  { rel: "stylesheet", href: styles },
  ...ReachModalCard.links,
];

export function useReachPageModal({
  modalInitiallyOpen = false,
  useModalCard = true,
}: {
  modalInitiallyOpen?: boolean;
  useModalCard?: boolean;
}) {
  let [showPageModal, setShowPageModal] = useState(modalInitiallyOpen);
  const openPageModal = () => setShowPageModal(true);
  const closePageModal = () => setShowPageModal(false);

  function ReachPageModal({ children }: { children: ReactNode }) {
    return (
      <DialogOverlay isOpen={showPageModal} onDismiss={closePageModal}>
        <DialogContent aria-label="Modal">
          {useModalCard ? (
            <ReachModalCard>{children}</ReachModalCard>
          ) : (
            children
          )}
        </DialogContent>
      </DialogOverlay>
    );
  }

  return {
    ReachPageModal,
    openPageModal,
    closePageModal,
  };
}
