import { DialogContent, DialogOverlay } from "@reach/dialog";
import { ReactNode } from "react";

import styles from "~/styles/components/interactive/modal/reach-dialog.css";

ReachUIModal.styles = styles;

export function ReachUIModal({
  children,
  isOpen,
  onDismiss,
}: {
  children: ReactNode;
  isOpen: boolean;
  onDismiss: any;
}) {
  return (
    <DialogOverlay isOpen={isOpen} onDismiss={onDismiss}>
      <DialogContent aria-label="Modal">{children}</DialogContent>
    </DialogOverlay>
  );
}
