import { useState } from "react";
import styles from "../../../styles/components/interactive/text-input.css";
import { FaSearch } from "react-icons/fa";
import { validateTextarea } from "../../utils/validation";

TextInput.styles = styles;

type InputProps = {
  variant?: "generic" | "search";
  offset?: "none" | "left" | "right";
  size?: "small" | "medium" | "large";
  className?: string;
  placeholder?: string;
  id: string;
  a11yLabel: string;
  validationFn?: any;
  defaultValue?: string;
};

/**
 * Required:
 * id
 * a11yLabel - hidden (used for screen readers)
 *
 * Optional:
 * className
 * placeholder
 * defaultValue
 * variant - search or generic
 * size - small, medium, or large
 * validationFn - validation function for input
 * offset - none, left, or right
 */
export function TextInput({
  offset = "none",
  variant = "generic",
  size = "medium",
  placeholder,
  className,
  id,
  a11yLabel,
  validationFn = validateTextarea,
  defaultValue,
}: InputProps) {
  let [inputValue, setInputValue] = useState(defaultValue || "");
  let [searchError, setSearchError] = useState("");
  let [showSearchError, setShowSearchError] = useState(false);

  function onSubmit(e: any) {
    let validationResult = validationFn(inputValue);
    let { valid, message } = validationResult;

    if (!valid) {
      e.preventDefault();

      setSearchError(message);
      setShowSearchError(true);

      return;
    }
  }

  if (variant === "search") {
    return (
      <form
        method="POST"
        className="remix-search-form-wrapper"
        data-variant={variant}
        data-offset={offset}
        onSubmit={onSubmit}
      >
        <div
          // @ts-ignore
          data-input-error={showSearchError}
          className="remix-text-input-error"
        >
          {searchError}
        </div>

        <input
          name={id}
          id={id}
          className={`remix-text-input${className ? " " + className : ""}`}
          type="text"
          data-size={size}
          data-variant={variant}
          data-offset={offset}
          placeholder={placeholder}
          value={inputValue}
          onBlur={(e) => {
            // console.log(e.target.value);
          }}
          onChange={(e) => {
            setInputValue(e.target.value);
            if (searchError) {
              let { valid, message } = validationFn(e.target.value);

              if (valid) {
                setShowSearchError(false);
              }
            }
          }}
        />
        <label className="visually-hidden" htmlFor={id}>
          {a11yLabel}
        </label>
        <button className="remix-search-input-button" type="submit">
          <FaSearch className="button-icon" aria-hidden="true" />
          <span className="visually-hidden">Submit Search</span>
        </button>
      </form>
    );
  }

  return (
    <input
      name={id}
      className={`remix-text-input${className ? " " + className : ""}`}
      type="text"
      data-size={size}
      data-variant={variant}
      data-offset={offset}
      placeholder={placeholder}
      value={inputValue}
    />
  );
}
